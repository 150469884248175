import React, { Component, Fragment } from "react"
import { slide as Menu } from "react-burger-menu"
import Link from "next/link"
import { withRouter } from "next/router"
import OpenInNewIcon from "@material-ui/icons/OpenInNew"
import PhoneIcon from "@material-ui/icons/Phone"
import { CDN_DOMAIN } from "../config/cdn"
import typesURLMatching from "../datas/typesURLMatching"
import { generateObfuscatedLink } from "../helpers/SEO"

class UECHeader extends Component {
    constructor(props) {
        super(props)

        this.state = {
            openedDropdown: {}
        }

        this.menus = {
            "/index": {
                menu: [
                    {
                        label: "Accueil",
                        link: `https://${props.config.websiteDomain}`,
                        mobileOnly: true
                    },
                    {
                        label: "Prix et recherches",
                        link: "#",
                        dropdown: [
                            {
                                label: "Bureaux",
                                link: `/${typesURLMatching['bureau']}`
                            },
                            {
                                label: "Locaux commerciaux",
                                link: `/${typesURLMatching['local-commercial']}`
                            },
                            {
                                label: "Fonds de commerce",
                                link: `/${typesURLMatching['fonds-commerce']}`
                            },
                            {
                                label: "Pop-up store",
                                link: `/${typesURLMatching['popup-store']}`
                            },
                            {
                                label: "Coworking",
                                link: `/${typesURLMatching['coworking']}`
                            },
                            {
                                label: "Entrepôts / Activités",
                                link: `/${typesURLMatching['entrepot']}`
                            },
                            {
                                label: "Terrains",
                                link: `/${typesURLMatching['terrain']}`
                            }
                        ]
                    }/*,
                    {
                        label: "Nos offres pros",
                        link: "/professionnels-immobilier",
                        dropdown: [
                            {
                                label: "Plateforme",
                                link: `/professionnels-immobilier`
                            },
                            {
                                label: "Carte",
                                link: `/carte`
                            }
                        ]
                    },
                    {
                        label: "Collectivités territoriales",
                        link: "/collectivites-territoriales"
                    }*/,
                    {
                        label: "Evènements",
                        link: "https://blog.unemplacement.com/petits-dejeuners/",
                        blank: true
                    },
                    {
                        label: "Guide",
                        link: `/guide`,
                        nolink: true
                    },
                    {
                        label: "Choisir une agence",
                        link: "/agence-immobiliere",
                        nolink: true
                    }
                ].filter(o => (props.hideLinks || []).indexOf(o.link) === -1),
                button: {
                    label: "Connexion",
                    link: "https://" + props.config.appDomain,
                    width: 125
                }
            },
            "/professionnels-immobilier": {
                menu: [
                    {
                        label: "Accueil",
                        link: `https://${props.config.websiteDomain}`,
                        mobileOnly: true
                    },
                    {
                        label: "Prix et recherches",
                        link: "#",
                        dropdown: [
                            {
                                label: "Bureaux",
                                link: `/${typesURLMatching['bureau']}`
                            },
                            {
                                label: "Locaux commerciaux",
                                link: `/${typesURLMatching['local-commercial']}`
                            },
                            {
                                label: "Fonds de commerce",
                                link: `/${typesURLMatching['fonds-commerce']}`
                            },
                            {
                                label: "Pop-up store",
                                link: `/${typesURLMatching['popup-store']}`
                            },
                            {
                                label: "Coworking",
                                link: `/${typesURLMatching['coworking']}`
                            },
                            {
                                label: "Entrepôts / Activités",
                                link: `/${typesURLMatching['entrepot']}`
                            },
                            {
                                label: "Terrains",
                                link: `/${typesURLMatching['terrain']}`
                            },
                            {
                                label: "Confier ma recherche",
                                link: "/",
                                customClass: "uec-dropdown-button"
                            }
                        ]
                    }/*,
                    {
                        label: "Nos offres pros",
                        link: "/professionnels-immobilier",
                        dropdown: [
                            {
                                label: "Plateforme",
                                link: `/professionnels-immobilier`
                            },
                            {
                                label: "Carte",
                                link: `/carte`
                            }
                        ]
                    },
                    {
                        label: "Collectivités territoriales",
                        link: "/collectivites-territoriales"
                    }*/,
                    {
                        label: "Evènements",
                        link: "https://blog.unemplacement.com/petits-dejeuners/",
                        blank: true
                    },
                    {
                        label: "Services & Tarifs",
                        link: "/services-tarifs"
                    }
                ],
                button: {
                    label: "Connexion",
                    link: "https://" + props.config.appDomain,
                    width: 125
                }
            },
            "/collectivites-territoriales": {
                menu: [
                    {
                        label: "Accueil",
                        link: `https://${props.config.websiteDomain}`,
                        mobileOnly: true
                    },
                    {
                        label: "Prix et recherches",
                        link: "#",
                        dropdown: [
                            {
                                label: "Bureaux",
                                link: `/${typesURLMatching['bureau']}`
                            },
                            {
                                label: "Local commercial",
                                link: `/${typesURLMatching['local-commercial']}`
                            },
                            {
                                label: "Fonds de commerce",
                                link: `/${typesURLMatching['fonds-commerce']}`
                            },
                            {
                                label: "Pop-up store",
                                link: `/${typesURLMatching['popup-store']}`
                            },
                            {
                                label: "Coworking",
                                link: `/${typesURLMatching['coworking']}`
                            },
                            {
                                label: "Entrepôts / Activités",
                                link: `/${typesURLMatching['entrepot']}`
                            },
                            {
                                label: "Terrains",
                                link: `/${typesURLMatching['terrain']}`
                            },
                            {
                                label: "Confier ma recherche",
                                link: "/",
                                customClass: "uec-dropdown-button"
                            }
                        ]
                    }/*,
                    {
                        label: "Nos offres pros",
                        link: "/professionnels-immobilier",
                        dropdown: [
                            {
                                label: "Plateforme",
                                link: `/professionnels-immobilier`
                            },
                            {
                                label: "Carte",
                                link: `/carte`
                            }
                        ]
                    },
                    {
                        label: "Collectivités territoriales",
                        link: "/collectivites-territoriales"
                    }*/,
                    {
                        label: "Evènements",
                        link: "https://blog.unemplacement.com/petits-dejeuners/",
                        blank: true
                    }
                ],
                button: {
                    label: "Connexion",
                    link: "https://" + props.config.appDomain,
                    width: 125
                }
            },
            "/services-tarifs": {
                menu: [
                    {
                        label: "Accueil",
                        link: `https://${props.config.websiteDomain}`,
                        mobileOnly: true
                    },
                    {
                        label: "Prix et recherches",
                        link: "#",
                        dropdown: [
                            {
                                label: "Bureaux",
                                link: `/${typesURLMatching['bureau']}`
                            },
                            {
                                label: "Local commercial",
                                link: `/${typesURLMatching['local-commercial']}`
                            },
                            {
                                label: "Fonds de commerce",
                                link: `/${typesURLMatching['fonds-commerce']}`
                            },
                            {
                                label: "Pop-up store",
                                link: `/${typesURLMatching['popup-store']}`
                            },
                            {
                                label: "Coworking",
                                link: `/${typesURLMatching['coworking']}`
                            },
                            {
                                label: "Entrepôts / Activités",
                                link: `/${typesURLMatching['entrepot']}`
                            },
                            {
                                label: "Terrains",
                                link: `/${typesURLMatching['terrain']}`
                            },
                            {
                                label: "Confier ma recherche",
                                link: "/",
                                customClass: "uec-dropdown-button"
                            }
                        ]
                    }/*,
                    {
                        label: "Nos offres pros",
                        link: "/professionnels-immobilier",
                        dropdown: [
                            {
                                label: "Plateforme",
                                link: `/professionnels-immobilier`
                            },
                            {
                                label: "Carte",
                                link: `/carte`
                            }
                        ]
                    },
                    {
                        label: "Collectivités territoriales",
                        link: "/collectivites-territoriales"
                    }*/,
                    {
                        label: "Evènements",
                        link: "https://blog.unemplacement.com/petits-dejeuners/",
                        blank: true
                    },
                    {
                        label: "Services & Tarifs",
                        link: "/services-tarifs"
                    }
                ],
                button: {
                    label: "Connexion",
                    link: "https://" + props.config.appDomain,
                    width: 125
                }
            },
            "/references": {
                menu: [
                    {
                        label: "Accueil",
                        link: `https://${props.config.websiteDomain}`,
                        mobileOnly: true
                    },
                    {
                        label: "Prix et recherches",
                        link: "#",
                        dropdown: [
                            {
                                label: "Bureaux",
                                link: `/${typesURLMatching['bureau']}`
                            },
                            {
                                label: "Local commercial",
                                link: `/${typesURLMatching['local-commercial']}`
                            },
                            {
                                label: "Fonds de commerce",
                                link: `/${typesURLMatching['fonds-commerce']}`
                            },
                            {
                                label: "Pop-up store",
                                link: `/${typesURLMatching['popup-store']}`
                            },
                            {
                                label: "Coworking",
                                link: `/${typesURLMatching['coworking']}`
                            },
                            {
                                label: "Entrepôts / Activités",
                                link: `/${typesURLMatching['entrepot']}`
                            },
                            {
                                label: "Terrains",
                                link: `/${typesURLMatching['terrain']}`
                            },
                            {
                                label: "Confier ma recherche",
                                link: "/",
                                customClass: "uec-dropdown-button"
                            }
                        ]
                    }/*,
                    {
                        label: "Nos offres pros",
                        link: "/professionnels-immobilier",
                        dropdown: [
                            {
                                label: "Plateforme",
                                link: `/professionnels-immobilier`
                            },
                            {
                                label: "Carte",
                                link: `/carte`
                            }
                        ]
                    },
                    {
                        label: "Collectivités territoriales",
                        link: "/collectivites-territoriales"
                    }*/,
                    {
                        label: "Evènements",
                        link: "https://blog.unemplacement.com/petits-dejeuners/",
                        blank: true
                    }
                ],
                button: {
                    label: "Connexion",
                    link: "https://" + props.config.appDomain,
                    width: 125
                }
            },
            "/recommandations": {
                menu: [
                    {
                        label: "Accueil",
                        link: `https://${props.config.websiteDomain}`,
                        mobileOnly: true
                    },
                    {
                        label: "Prix et recherches",
                        link: "#",
                        dropdown: [
                            {
                                label: "Bureaux",
                                link: `/${typesURLMatching['bureau']}`
                            },
                            {
                                label: "Local commercial",
                                link: `/${typesURLMatching['local-commercial']}`
                            },
                            {
                                label: "Fonds de commerce",
                                link: `/${typesURLMatching['fonds-commerce']}`
                            },
                            {
                                label: "Pop-up store",
                                link: `/${typesURLMatching['popup-store']}`
                            },
                            {
                                label: "Coworking",
                                link: `/${typesURLMatching['coworking']}`
                            },
                            {
                                label: "Entrepôts / Activités",
                                link: `/${typesURLMatching['entrepot']}`
                            },
                            {
                                label: "Terrains",
                                link: `/${typesURLMatching['terrain']}`
                            },
                            {
                                label: "Confier ma recherche",
                                link: "/",
                                customClass: "uec-dropdown-button"
                            }
                        ]
                    }/*,
                    {
                        label: "Nos offres pros",
                        link: "/professionnels-immobilier",
                        dropdown: [
                            {
                                label: "Plateforme",
                                link: `/professionnels-immobilier`
                            },
                            {
                                label: "Carte",
                                link: `/carte`
                            }
                        ]
                    },
                    {
                        label: "Collectivités territoriales",
                        link: "/collectivites-territoriales"
                    }*/,
                    {
                        label: "Evènements",
                        link: "https://blog.unemplacement.com/petits-dejeuners/",
                        blank: true
                    }
                ],
                button: {
                    label: "Connexion",
                    link: "https://" + props.config.appDomain,
                    width: 125
                }
            }
        }
    }

    render() {
        const menu = this.menus[this.props.router.pathname]
            ? this.menus[this.props.router.pathname].menu
            : this.menus["/index"].menu
        let button = null

        if (
            this.menus[this.props.router.pathname] &&
            this.menus[this.props.router.pathname].button
        ) {
            button = this.menus[this.props.router.pathname].button
        } else if (this.menus["/index"] && this.menus["/index"].button) {
            button = this.menus["/index"].button
        }

        return (
            <Fragment>
                <Menu
                    styles={{
                        bmCrossButton: {
                            height: "35px",
                            width: "40px"
                        }
                    }}
                    burgerButtonClassName="header-burger-button"
                >
                    {menu.map((entry, i) => (
                        <div key={i} style={{ position: "relative" }}>
                            {!entry.dropdown && !entry.nolink && (
                                <Link href={entry.link}>
                                    <a
                                        className={`menu-item${
                                            entry.link ===
                                            this.props.router.asPath
                                                ? " hover"
                                                : ""
                                        }`}
                                        target={
                                            entry.blank ? "_blank" : undefined
                                        }
                                    >
                                        {entry.label}
                                    </a>
                                </Link>
                            )}
                            {!entry.dropdown && entry.nolink && (
                                <a
                                    href={entry.link}
                                    className={`menu-item${
                                        entry.link ===
                                        this.props.router.asPath
                                            ? " hover"
                                            : ""
                                    }`}
                                    target={
                                        entry.blank ? "_blank" : undefined
                                    }
                                >
                                    {entry.label}
                                </a>
                            )}
                            {entry.dropdown && (
                                <a
                                    className={`menu-item${
                                        this.state.openedDropdown[i]
                                            ? " hover"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        if (this.state.openedDropdown[i]) {
                                            this.setState({
                                                openedDropdown: {
                                                    ...this.state
                                                        .openedDropdown,
                                                    [i]: false
                                                }
                                            })
                                        } else {
                                            this.setState({
                                                openedDropdown: {
                                                    ...this.state
                                                        .openedDropdown,
                                                    [i]: true
                                                }
                                            })
                                        }
                                    }}
                                >
                                    {entry.label}
                                </a>
                            )}
                            {entry.dropdown &&
                                this.state.openedDropdown[i] && (
                                    <div
                                        style={{
                                            marginLeft: 10,
                                            marginTop: 30,
                                            border: "1px solid #0db3ac",
                                            padding: 5,
                                            paddingLeft: 10,
                                            borderRadius: 5
                                        }}
                                    >
                                        {entry.dropdown.map((o, j) => {
                                            return (
                                                <div
                                                    key={`${i}_${j}`}
                                                    style={{
                                                        position: "relative",
                                                        marginTop: 20,
                                                        marginBottom: 20
                                                    }}
                                                >
                                                    <Link href={o.link}>
                                                        <a
                                                            className={`menu-item${
                                                                o.link ===
                                                                this.props
                                                                    .router
                                                                    .asPath
                                                                    ? " hover"
                                                                    : ""
                                                            }`}
                                                            target={
                                                                o.blank
                                                                    ? "_blank"
                                                                    : undefined
                                                            }
                                                        >
                                                            {o.label}
                                                        </a>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                        </div>
                    ))}
                    <div>
                        <a
                            href={button.link}
                            className="uec-button"
                            style={{ width: button.width || 150 }}
                        >
                            {button.label}
                        </a>
                    </div>
                    <a
                        href="tel:+33622497152"
                        className="green-link"
                        style={{
                            fontSize: 14,
                            display: "flex",
                            alignItems: "center",
                            marginRight: 30
                        }}
                    >
                        <PhoneIcon
                            style={{ color: "#0db3ac", marginRight: 5 }}
                        />
                        <Fragment>06 22 49 71 52</Fragment>
                    </a>
                </Menu>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        paddingLeft: 20,
                        paddingRight: 20,
                        height: 62.5
                    }}
                >
                    <div style={{ flexGrow: 1, maxWidth: 160 }}>
                        <a href="/" style={{ border: 0, margin: "auto" }}>
                            <img
                                src={`${CDN_DOMAIN}/static/logo_green.png`}
                                alt="Unemplacement.com"
                                title="Unemplacement.com"
                                className="logo-uec"
                                style={{
                                    width: 160
                                }}
                            />
                        </a>
                    </div>
                    <div className="menu" style={{ flexGrow: 2 }}>
                        <ul
                            style={{
                                marginLeft: 15,
                                paddingLeft: 0,
                                paddingRight: 0
                            }}
                        >
                            {menu
                                .filter(
                                    item =>
                                        typeof item.mobileOnly === "undefined"
                                )
                                .map((entry, i) => (
                                    <li
                                        key={i}
                                        className={
                                            entry.dropdown
                                                ? "dropdown-button"
                                                : undefined
                                        }
                                    >
                                        {entry.dropdown && (
                                            <div
                                                style={{
                                                    position: "relative",
                                                    zIndex: 10,
                                                    top: -10
                                                }}
                                            >
                                                <ul className="dropdown">
                                                    {entry.dropdown.map((o, i) => (
                                                        <li key={i}>
                                                            <a
                                                                className={
                                                                    o.customClass
                                                                        ? o.customClass
                                                                        : "dropdown-link"
                                                                }
                                                                href={o.link}
                                                                target={
                                                                    o.blank
                                                                        ? "_blank"
                                                                        : undefined
                                                                }
                                                            >
                                                                {o.label}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                        {!entry.nolink &&
                                            <Link href={entry.link}>
                                                <a
                                                    className={`${
                                                        entry.link ===
                                                        this.props.router.asPath
                                                            ? "hover"
                                                            : ""
                                                    }`}
                                                    target={
                                                        entry.blank
                                                            ? "_blank"
                                                            : undefined
                                                    }
                                                >
                                                    {entry.label}
                                                </a>
                                            </Link>
                                        }
                                        {entry.nolink &&
                                            <a
                                                href={entry.link}
                                                className={`${
                                                    entry.link ===
                                                    this.props.router.asPath
                                                        ? "hover"
                                                        : ""
                                                }`}
                                                target={
                                                    entry.blank
                                                        ? "_blank"
                                                        : undefined
                                                }
                                            >
                                                {entry.label}
                                            </a>
                                        }
                                    </li>
                                ))}
                        </ul>
                    </div>
                    <a
                        href="tel:+33622497152"
                        className="hideForm green-link tel-link"
                        style={{
                            fontSize: 14,
                            display: "flex",
                            alignItems: "center",
                            marginRight: 30
                        }}
                    >
                        <PhoneIcon
                            style={{ color: "#0db3ac", marginRight: 5 }}
                        />
                        <Fragment>06 22 49 71 52</Fragment>
                    </a>
                    {button && (
                        <div
                            className="hideForm"
                            style={{
                                flexGrow: 1,
                                maxWidth: button.width || 150
                            }}
                        >
                            <a
                                href={button.link}
                                className="uec-button hideConnectionButton"
                                style={{ width: button.width || 150 }}
                            >
                                {button.label}
                            </a>
                        </div>
                    )}
                    {!this.props.hideProButton &&
                        generateObfuscatedLink(
                            "/professionnels-immobilier",
                            <div
                                style={{
                                    position: "relative",
                                    top: -1
                                }}
                            >
                                Nos offres pros{' '}
                                <OpenInNewIcon
                                    style={{
                                        color:
                                            'black',
                                        width: 16,
                                        height: 16,
                                        marginLeft: 1,
                                        position:
                                            'relative',
                                        top: 3
                                    }}
                                />
                            </div>,
                            "black-olink uec-pro-button cta-pro-index hideConnectionButton",
                            {
                                marginLeft: 20,
                                fontSize: 15
                            }
                        )
                    }
                </div>
            </Fragment>
        )
    }
}

export default withRouter(UECHeader)
