import React, { PureComponent } from "react"
import Head from "next/head"
import { isMobileOnly } from "react-device-detect"
import Router, { withRouter } from "next/router"

class CrispBlock extends PureComponent {
    constructor (props) {
        super(props)

        this.state = {
            isMobileOnly: null
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.props.websiteID !== nextProps.websiteID ||
            this.state.isMobileOnly !== nextState.isMobileOnly ||
            this.props.router.pathname !== nextProps.router.pathname
        ) {
            return true
        }

        return false
    }

    componentDidMount() {
        if (isMobileOnly !== this.state.isMobileOnly) {
            this.setState({ isMobileOnly })
        }
    }

    render() {
        const { websiteID } = this.props

        if (
            this.props.router &&
            [
                '/onboarding',
                '/onboarding-bureaux',
                '/onboarding-entrepots',
                '/onboarding-fonds-de-commerce',
                '/onboarding-investissement',
                '/onboarding-local-commercial',
                '/onboarding-pop-up-store',
                '/onboarding-terrain'
            ].indexOf(this.props.router.pathname) !== -1 &&
            this.state.isMobileOnly
        ) {
            return (
                <Head>
                    <script
                        dangerouslySetInnerHTML={{
                            __html: `
                                if (window.$crisp) {
                                    $crisp.push(['do', 'chat:hide']);
                                }
                            `
                        }}
                    />
                </Head>
            )
        }

        if (
            (
                this.props.router &&
                ['/toutes-les-annonces-v2', '/liste-annonces-v2'].indexOf(this.props.router.pathname) !== -1 &&
                this.state.isMobileOnly
            ) || this.state.isMobileOnly === null
        ) {
            return (
                <Head>
                    <script
                        dangerouslySetInnerHTML={{
                            __html: `
                                if (window.$crisp) {
                                    $crisp.push(['do', 'chat:hide']);
                                }
                            `
                        }}
                    />
                </Head>
            )
        }

        return (
            <Head>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                            if (!window.$crisp) {
                                window.$crisp=[];
                                window.CRISP_WEBSITE_ID="${websiteID || '20d3b22f-0560-48f9-a6e6-7fe6362a8553'}";
                                (function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
                            }
                            if (window.$crisp) {
                                $crisp.push(['do', 'chat:show']);
                            }
                        `
                    }}
                />
            </Head>
        )
    }
}

export default withRouter(CrispBlock)